import React, { Component } from 'react';
import axios from 'axios';
import { Page, Button, Card, Form, FormLayout, TextField, InlineError, Spinner } from '@shopify/polaris';
import store from 'store2';
import CommonHelper from '../Helpers/Common';
export default class Install extends Component {
  
  state = {
    url: "",
    shop: this.props.shop,
    loading: false,
    show_spinner: false
  };

  validation(){
    var url = this.state.url;
    if(url && url !== "" && url.trim() !== "" && url.indexOf(".myshopify.com") > -1){
      return true;
    }
    else{
      return false;
    }
  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  sendLogs(data, shop){
    var host_name = CommonHelper.getHostName();
    axios.post(host_name + '/logs?shop=' + shop, {data: data})
    .then(response => {
    })
    .catch(error => {
      
    });
  }

  componentDidMount() {
    /* Check if app is in embedded state [iframe], wo don't need to show this page, this page was loaded from some bug */
    if(this.inIframe()){

      // Get shop from frame ancestor
      if(window.location.ancestorOrigins.length > 0){
        try{

          this.setState({
            show_spinner: true
          });

          const shop = window.location.ancestorOrigins[0]
          .replace("https://", "")
          .replace("http://", "");

          // if not admin domain
          if(shop !== "admin.shopify.com"){
            // Update localStaoge variables
            // store('shop', shop);
            window.location.replace("/?cache=clear&shop="+shop);
          }
        } catch (e) {
          this.sendLogs({
            origin: "Install.js-app238 at line 64",
            error: e && typeof e.message !== "undefined" ? e.message : "Something went wrong" 
          }, window.location.ancestorOrigins[0]);
        }
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const shops = {
      url: this.state.url
    }
    if (shops.url && shops.url !== "" && shops.url.indexOf(".myshopify.com") > -1) {
      let shopify_base_url = shops.url;
      try {
        shopify_base_url = shopify_base_url.replace("https://", "")
        .replace("http://", "")
        .replace(".myshopify.com\/", ".myshopify.com")
        .replace(".myshopify.com/", ".myshopify.com");
      } catch (e) { }
      if (shopify_base_url) {
        this.setState({loading:true});
        axios.get(process.env.REACT_APP_SERVER_URL + '/shopapp?shop=' + shopify_base_url)
        .then(response => {
          if (response.data.redirect_uri && response.data.shop === shopify_base_url) {
            window.top.location.href = response.data.redirect_uri;
          }
        })
        .catch(error => {
          this.setState({ loading:false });
        });
      }
    }
  };
  handleChange = (field) => {
    return (value) => this.setState({ [field]: value });
  };
  render() {
    const { url } = this.state;

    if(this.state.show_spinner){
      return (
        <div className="snipper-container">
          <center><Spinner color="teal" size="large" /></center>
        </div>
      );
    }
    else{
      return (
        <Page
          title="Installation - Wholesale - All in one"
        >
          <Card sectioned>
            <Form onSubmit={this.handleSubmit}>
              <FormLayout>
                <TextField
                  autoComplete={false}
                  value={url}
                  onChange={(v) => { this.setState({url:v}) }}
                  label="Shopify base URL"
                  type="text"
                  fieldID="storeURL"
                  placeholder="Enter shopify URL"
                  helpText="Example: https://website.myshopify.com"
                />
                {(this.validation() ? null : <InlineError message="Shopify URL is required" fieldID="storeURL" />)}
                <Button
                  primary
                  submit
                  loading={this.state.loading}
                  disabled={!this.validation()}
                > Install Application </Button>
              </FormLayout>
            </Form>
          </Card>
        </Page>
      );
    }
  }
}